import fetch from './interceptors'

const AuthService = {
    login: function (data: any) {
        return fetch({
            url: '/api/v2/auth/login',
            method: 'post',
            headers: {
                'public-request': 'true'
            },
            data: data
        })
    },
    logout: function (data: any) {
        return fetch({
            url: '/api/v2/auth/logout',
            method: 'post',
            headers: {
                'public-request': 'true'
            },
            data: data
        })
    },
    check: function () {
        return fetch({
            url: '/api/v2/auth/user',
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    },
    getUsers: function (params: any) { 
        return fetch({
            url: `/api/corp/ajaxuser`,
            method: 'get',
            params
        })
    }
}

export default AuthService