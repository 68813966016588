import { createContext, ReactNode, useReducer } from 'react';
// @types
import { ActionMap } from '../@types/auth';
import { Quiz } from 'src/@types/quiz';
import QuizService from 'src/services/QuizService';
import { ArticleType, PlacementType } from 'src/@types/package';
import ArticleService from 'src/services/ArticleService';
import PlacmentService from 'src/services/PlacementService';

// ----------------------------------------------------------------------

enum Types {
  RegisterQuiz = 'REGISTER_QUIZ',
  RegisterArticle = 'REGISTER_ARTICLE',
  RegisterPlacement = "REGISTER_PLACEMENT",
}

type ObjectPayload = {
  [Types.RegisterQuiz]: {
    quiz: Quiz;
  };
  [Types.RegisterArticle]: {
    article: ArticleType;
  };
  [Types.RegisterPlacement]: {
    placement: PlacementType;
  };
};

export type ObjectContextState = {
  quiz: Quiz | null;
  article: ArticleType | null;
  placement: PlacementType | null;
};

export type ObjectContextType = {
  quiz: Quiz | null;
  article: ArticleType | null;
  placement: PlacementType | null;
  registerQuiz: (id: number | string | undefined) => Promise<void>;
  registerArticle: (id: number | string | undefined) => Promise<void>;
  registerPlacement: (id: string) => Promise<void>;
};

export type ObjectActions = ActionMap<ObjectPayload>[keyof ActionMap<ObjectPayload>];

const initialState: ObjectContextState = {
  quiz: null,
  article: null,
  placement: null,
};

const ObjectReducer = (state: ObjectContextState, action: ObjectActions) => {
  switch (action.type) {
    case Types.RegisterQuiz:
      return {
        ...state,
        quiz: action.payload.quiz,
      };
    case Types.RegisterArticle:
      return {
        ...state,
        article: action.payload.article,
      };
    case Types.RegisterPlacement:
      return {
        ...state,
        placement: action.payload.placement,
      };
    default:
      return state;
  }
};

const ObjectContext = createContext<ObjectContextType | null>(null);

// ----------------------------------------------------------------------

type PackageProviderProps = {
  children: ReactNode;
};

function ObjectProvider({ children }: PackageProviderProps) {
  const [state, dispatch] = useReducer(ObjectReducer, initialState);

  const registerQuiz = async (id: number | string | undefined) => {
    const response = await QuizService.getQuizOverview(id);
    dispatch({
      type: Types.RegisterQuiz,
      payload: {
        quiz: response.data,
      },
    });
  };

  const registerArticle = async (id: number | string | undefined) => {
    const response = await ArticleService.getArticle(id);
    dispatch({
      type: Types.RegisterArticle,
      payload: {
        article: response.data,
      },
    });
  };

  const registerPlacement = async (id: string) => {
    const response = await PlacmentService.getPlacement(id);
    dispatch({
      type: Types.RegisterPlacement,
      payload: {
        placement: response.data,
      },
    });
  };

  return (
    <ObjectContext.Provider
      value={{
        ...state,
        registerQuiz,
        registerPlacement,
        registerArticle,
      }}
    >
      {children}
    </ObjectContext.Provider>
  );
}

export { ObjectContext, ObjectProvider };
