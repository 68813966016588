import fetch from './interceptors';

const PackageService = {
  getPackages: function (params?: object) {
    return fetch({
      url: '/api/v2/package/action',
      method: 'get',
      params,
    });
  },
  addPackageRequest: function (data: any) {
    return fetch({
      url: '/api/corp/create/bkrequest',
      method: 'post',
      data,
    });
  },
  getPackageOverview: function (id: number | string | undefined, params?: object) {
    return fetch({
      url: `/api/v2/package/action/${id}`,
      method: 'get',
      params,
    });
  },
  getPackageWords: function (id: number | string, params?: object) {
    return fetch({
      url: `/api/v2/package/action/${id}/word`,
      method: 'get',
      params,
    });
  },
  getPosType: function (languageId: number | string, params?: object) {
    return fetch({
      url: `/api/corp/partsofspeech/${languageId}`,
      method: 'get',
      params,
    });
  },
  getWordDetail: function (packageId: number | string, wordId: number | string, params?: object) {
    return fetch({
      url: `/api/v2/package/action/${packageId}/word/${wordId}`,
      method: 'get',
      params,
    });
  },
  createWord: function (id: number | string, data: any) {
    return fetch({
      url: `/api/v2/package/action/${id}/word/`,
      method: 'post',
      data,
    });
  },
  updateWord: function (packageId: number | string, id: number | string, data: any) {
    return fetch({
      url: `/api/v2/package/action/${packageId}/word/${id}`,
      method: 'post',
      data,
    });
  },
  deleteWord: function (packageId: number | string, id: number | string, params?: object) {
    return fetch({
      url: `/api/v2/package/action/${packageId}/word/${id}`,
      method: 'delete',
      params,
    });
  },
  getLanguages: function (params?: object) {
    return fetch({
      url: `/api/v2/utility/language`,
      method: 'get',
      params,
    });
  },
  getIcons: function (params?: object) {
    return fetch({
      url: `/api/v2/utility/icon`,
      method: 'get',
      params,
    });
  },
  getMainCategories: function (params?: object) {
    return fetch({
      url: `/api/v2/utility/category`,
      method: 'get',
      params,
    });
  },
  createPackage: function (data: any) {
    return fetch({
      url: `/api/v2/package/action/`,
      method: 'post',
      data,
    });
  },
  editPackage: function ({ data, id }: { data: any; id: number }) {
    return fetch({
      url: `/api/v2/package/action/${id}`,
      method: 'put',
      data,
    });
  },
  deletePackage: function ({ id }: { id: number | undefined }) {
    return fetch({
      url: `/api/v2/package/action/${id}`,
      method: 'delete',
    });
  },
  createRequest: function (data: any) {
    return fetch({
      url: `/api/v2/package/request`,
      method: 'post',
      data,
    });
  },
  getPackageRequests: function (params?: object) {
    return fetch({
      url: `/api/v2/package/request`,
      method: 'get',
      params,
    });
  },
  acceptRequest: function ({ id, data }: { id: any; data: any }) {
    return fetch({
      url: `/api/v2/package/request/${id}/status`,
      method: 'post',
      data,
    });
  },
  uploadIcon: function ({ data }: { data: any }) {
    return fetch({
      url: `/api/v2/utility/icon`,
      method: 'post',
      data,
    });
  },
  getSimilarWords: function (params?: object) {
    return fetch({
      url: `/api/v2/package/word/other`,
      method: 'get',
      params,
    });
  },
  getPopups: function (params?: object) {
    return fetch({
      url: '/api/v2/utility/popup',
      method: 'get',
      params,
    });
  },
  createPopup: function (data?: object) {
    return fetch({
      url: '/api/v2/utility/popup',
      method: 'post',
      data,
    });
  },
  updatePopup: function (index: string | number, data?: object) {
    return fetch({
      url: `/api/v2/utility/popup/${index}`,
      method: 'post',
      data,
    });
  },
  showPopup: function (index: string | number, params?: object) {
    return fetch({
      url: `/api/v2/utility/popup/${index}`,
      method: 'get',
      params,
    });
  },
};

export default PackageService;
