// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Alert, CircularProgress, TextField, TextFieldProps } from '@mui/material';
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import { useRef, useState } from 'react';
import axios from 'axios';
// ----------------------------------------------------------------------

interface IProps {
  name: string;
  spell?: boolean;
}

export default function RHFTextField({ name, spell, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();
  const textRef = useRef<any>(null);

  const [visible, setVisible] = useState(false);
  const [words, setWords] = useState([]);
  const [loading, setLoading] = useState(false);

  const onBlur = () => {
    if (!spell) return;
    setVisible(false);
    setLoading(true);
    const data = { string: textRef.current.value };
    data.string.length > 0 ?
      axios
        .post('https://api.bolor.net/v1.2/spell-check-short', data, {
          headers: {
            token: 'bd8cb798394521f9e2288538e166845099445993985b8daf2db4084374b54d5d',
            'Content-Type': 'text/plain',
          },
        })
        .then((res) => {
          setVisible(res.data.length > 0);
          setWords(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        }) : setLoading(false);
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <GrammarlyEditorPlugin clientId="client_ERvBPiUSDHgpd78WeDP2E4">
            <TextField
              {...field}
              fullWidth
              onBlur={onBlur}
              error={!!error}
              helperText={error?.message}
              {...other}
              inputRef={textRef}
              InputProps={{
                endAdornment:
                  spell && loading ? <CircularProgress disableShrink={true} size={20} /> : null,
              }}
            />
          </GrammarlyEditorPlugin>
        )}
      />
      {visible && spell && (
        <Alert severity="error">Алдаатай бичвэр байна. {words.map((wo) => `${wo}, `)}</Alert>
      )}
    </>
  );
}
