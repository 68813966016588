// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel, FormControlLabelProps } from '@mui/material';

// ----------------------------------------------------------------------

type IProps = Omit<FormControlLabelProps, 'control'>;

interface Props extends IProps {
  name: string;
  defaultChecked?: any;  
  defaultValue?: string | number | undefined; 
}

export default function RHFSwitch({ name, defaultChecked, defaultValue, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Switch {...field} checked={field.value} defaultValue={defaultValue} defaultChecked={defaultChecked} />}
        />
      }
      {...other}
    />
  );
}
