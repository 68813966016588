import { ElementType, Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// guards
import useAuth from '../hooks/useAuth';
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import DashboardLayout from '../layouts/dashboard';
// guards
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
// components
import { LessonProvider } from 'src/contexts/LessonContext';
import { ObjectProvider } from 'src/contexts/ObjectContext';
import { PackageProvider } from 'src/contexts/PackageContext';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AUTH } from './paths';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { element: <Navigate to={PATH_AUTH.login} replace />, index: true },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        // Users
        {
          path: 'users',
          children: [
            { element: <Navigate to="/dashboard/users/all" replace />, index: true },
            { path: 'all', element: <UserList /> },
          ],
        },

        // Temp file harsan uedee ustgana
        { path: 'sort', element: <SortArticle /> },

        // Packages route
        {
          path: 'packages',
          children: [
            { element: <Navigate to="/dashboard/packages/all" replace />, index: true },
            { path: 'all', element: <PackageList /> },
            { path: 'lesson', element: <LessonList /> },
            {
              path: 'lesson/:id',
              element: (
                <LessonProvider>
                  <LessonDetail type="list" />
                </LessonProvider>
              ),
            },
            {
              path: 'lesson/:id/print',
              element: (
                <LessonProvider>
                  <LessonPrint />
                </LessonProvider>
              ),
            },
            {
              path: 'lesson/:id/slide',
              element: (
                <LessonProvider>
                  <LessonSlide />
                </LessonProvider>
              ),
            },
            {
              path: 'lesson/:id/exam',
              element: (
                <LessonProvider>
                  <LessonDetail type="exam" />
                </LessonProvider>
              ),
            },
            {
              path: 'lesson/:id/student',
              element: (
                <LessonProvider>
                  <LessonStudent />
                </LessonProvider>
              ),
            },
            {
              path: ':id',
              element: (
                <PackageProvider>
                  <PackageDetail />
                </PackageProvider>
              ),
            },
            {
              path: 'create',
              element: (
                <PackageProvider>
                  <CreatePackage type="create" />
                </PackageProvider>
              ),
            },
            {
              path: 'create/request',
              element: (
                <PackageProvider>
                  <CreatePackageRequest />
                </PackageProvider>
              ),
            },
            {
              path: 'requests',
              element: (
                <PackageProvider>
                  <PackageRequests />
                </PackageProvider>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <PackageProvider>
                  <CreatePackage type="edit" />
                </PackageProvider>
              ),
            },
            {
              path: ':id/word/create',
              element: (
                <PackageProvider>
                  <CreateWord type="create" />
                </PackageProvider>
              ),
            },
            {
              path: ':id/word/:word',
              element: (
                <PackageProvider>
                  <CreateWord type="update" />
                </PackageProvider>
              ),
            },
          ],
        },
        // -- test --
        { path: 'economic', element: <EconomicInvoice /> },
        { path: 'economicList', element: <EcoProdEconomicInvoice /> },
        { path: 'GeneralBooking', element: <GeneralBooking /> },
        // -- test --

        // Articles
        {
          path: 'articles',
          children: [
            { element: <Navigate to="/dashboard/articles/all" replace />, index: true },
            { path: 'all', element: <ArticleMain /> },
            { path: ':id', element: <ArticleParagraph /> },
            { path: 'create', element: <ArticleCreate type="create" /> },
            {
              path: ':id/question',
              element: (
                <ObjectProvider>
                  <ArticleQuestions />
                </ObjectProvider>
              ),
            },
            { path: ':id/edit', element: <ArticleCreate type="edit" /> },
          ],
        },
        // Quiz
        {
          path: 'quiz',
          children: [
            { element: <Navigate to="/dashboard/quiz/all" replace />, index: true },
            { path: 'all', element: <QuizList /> },
            {
              path: ':id',
              element: (
                <ObjectProvider>
                  <QuizDetail />
                </ObjectProvider>
              ),
            },
          ],
        },
        // Quiz
        {
          path: 'greeting',
          children: [{ element: <GreetingList />, index: true }],
        },
        {
          path: 'popup',
          children: [
            { element: <PopupList />, index: true },
            { path: 'create', element: <PopupCreate /> },
            { path: 'edit/:id', element: <PopupCreate /> },
          ],
        },
        // placment
        {
          path: 'placement',
          children: [
            { element: <Navigate to="/dashboard/placement/list" replace />, index: true },
            { path: 'list', element: <PlacementList /> },
            {
              path: 'list/:id',
              element: (
                <ObjectProvider>
                  <PlacementQuestions />
                </ObjectProvider>
              ),
            },
          ],
        },
        // user
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        // notification
        { path: 'notification', element: <Notification /> },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const EconomicInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const EcoProdEconomicInvoice = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
);
const EcommerceProductList = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductList'))
);
const EcommerceProductCreate = Loadable(
  lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
);
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Packages
const PackageList = Loadable(lazy(() => import('../pages/dashboard/packages')));
const CreatePackage = Loadable(lazy(() => import('../pages/dashboard/packages/create')));
const CreatePackageRequest = Loadable(
  lazy(() => import('../pages/dashboard/packages/create/packageRequest'))
);
const PackageRequests = Loadable(lazy(() => import('../pages/dashboard/packages/requests')));
const PackageDetail = Loadable(lazy(() => import('../pages/dashboard/packages/detail')));
const LessonList = Loadable(lazy(() => import('../pages/dashboard/packages/lesson')));
const LessonDetail = Loadable(lazy(() => import('../pages/dashboard/packages/lesson/detail')));
const LessonPrint = Loadable(lazy(() => import('../pages/dashboard/packages/lesson/print')));
const LessonSlide = Loadable(lazy(() => import('../pages/dashboard/packages/lesson/slide')));
const LessonStudent = Loadable(lazy(() => import('../pages/dashboard/packages/lesson/student')));
const CreateWord = Loadable(lazy(() => import('../pages/dashboard/packages/word/create')));

// Users
const UserList = Loadable(lazy(() => import('../pages/dashboard/users')));

// Articles
const ArticleMain = Loadable(lazy(() => import('../pages/dashboard/artices')));
const ArticleParagraph = Loadable(lazy(() => import('../pages/dashboard/artices/paragraph')));
const ArticleCreate = Loadable(lazy(() => import('../pages/dashboard/artices/create')));
const ArticleQuestions = Loadable(lazy(() => import('../pages/dashboard/artices/question')));

// Quiz
const QuizList = Loadable(lazy(() => import('../pages/dashboard/quiz')));
const QuizDetail = Loadable(lazy(() => import('../pages/dashboard/quiz/detail')));

// Placements
const PlacementList = Loadable(lazy(() => import('../pages/dashboard/placements')));
const PlacementQuestions = Loadable(lazy(() => import('../pages/dashboard/placements/questions')));

// temp file
const SortArticle = Loadable(lazy(() => import('../pages/dashboard/SortArticle')));

const GreetingList = Loadable(lazy(() => import('../pages/dashboard/greetings/List')));
const PopupList = Loadable(lazy(() => import('../pages/dashboard/popup/List')));
const PopupCreate = Loadable(lazy(() => import('../pages/dashboard/popup/Create')));
// Notification
const Notification = Loadable(lazy(() => import('../pages/dashboard/Notification')));
