import fetch from './interceptors'

const PlacmentService = {
    list: function () {
        return fetch({
            url: '/api/v2/placement/action',
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    },
    // questions
    getPlacement: function(id: string) {
        return fetch({
            url: `/api/v2/placement/action/${id}`,
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    }
}

export default PlacmentService