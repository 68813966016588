import fetch from './interceptors';

const QuizService = {
  getQuizzes: function (params?: object) {
    return fetch({
      url: '/api/v2/quiz/action',
      method: 'get',
      params,
    });
  },
  getQuizOverview: function (id: number | string | undefined, params?: object) {
    return fetch({
      url: `/api/v2/quiz/action/${id}`,
      method: 'get',
      params,
    });
  },
  createQuiz: function (data?: object) {
    return fetch({
      url: '/api/v2/quiz/action',
      method: 'post',
      data,
    });
  },
  updateQuiz: function (id: number | string, data?: object) {
    return fetch({
      url: `/api/v2/quiz/action/${id}`,
      method: 'post',
      data,
    });
  },
  deleteQuiz: function (id: number | string, data?: object) {
    return fetch({
      url: `/api/v2/quiz/action/${id}`,
      method: 'delete',
      data,
    });
  },
  getQuizAwards: function (id: number | string | undefined, params?: object) {
    return fetch({
      url: `/api/v2/quiz/action/${id}/award`,
      method: 'get',
      params,
    });
  },
  createQuizAward: function (id: number | string | undefined, data?: object) {
    return fetch({
      url: `/api/v2/quiz/action/${id}/award`,
      method: 'post',
      data,
    });
  },
  updateQuizAward: function (
    id: number | string | undefined,
    awardId: number | string,
    data?: object
  ) {
    return fetch({
      url: `/api/v2/quiz/action/${id}/award/${awardId}`,
      method: 'put',
      data,
    });
  },
  deleteQuizAward: function (
    id: number | string | undefined,
    awardId: number | string,
    data?: object
  ) {
    return fetch({
      url: `/api/v2/quiz/action/${id}/award/${awardId}`,
      method: 'delete',
      data,
    });
  },
  getQuizQuestion: function (params?: object) {
    return fetch({
      url: `/api/v2/utility/question`,
      method: 'get',
      params,
    });
  },
  createQuizQuestion: function (data?: object) {
    return fetch({
      url: `/api/v2/utility/question`,
      method: 'post',
      data,
    });
  },
  updateQuizQuestion: function (questionId: number | string, data?: object) {
    return fetch({
      url: `/api/v2/utility/question/${questionId}`,
      method: 'post',
      data,
    });
  },
  deleteQuizQuestion: function (questionId: number | string, data?: object) {
    return fetch({
      url: `/api/v2/utility/question/${questionId}`,
      method: 'delete',
      data,
    });
  },
};

export default QuizService;
