// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from 'src/components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  language: <Iconify icon="clarity:language-solid" />,
  lesson: <Iconify icon="fluent:notebook-24-filled" />,
  article: <Iconify icon="ic:outline-article" />,
  quiz: <Iconify icon="material-symbols:quiz" />,
  placement: <Iconify icon="ph:exam-bold" />,
  notification: <Iconify icon="material-symbols:notification-add-outline" />,
};

const navConfig = [
  // Management
  // GENERAL
  {
    subheader: 'general',
    items: [
      {
        title: 'user',
        path: PATH_DASHBOARD.users.all,
        icon: ICONS.user,
      },
      {
        title: 'package',
        path: PATH_DASHBOARD.packages.all,
        icon: ICONS.language,
      },
      {
        title: 'Article',
        path: PATH_DASHBOARD.article.root,
        icon: ICONS.article,
      },
      {
        title: 'Quiz',
        path: PATH_DASHBOARD.quiz.root,
        icon: ICONS.quiz,
      },
      {
        title: 'Placement',
        path: PATH_DASHBOARD.placement.root,
        icon: ICONS.placement,
      },
      {
        title: 'Feedback',
        path: PATH_DASHBOARD.chat.root,
        icon: ICONS.chat,
      },
      {
        title: 'Greeting',
        path: PATH_DASHBOARD.root + '/greeting',
        icon: ICONS.blog,
      },
      {
        title: 'Popup',
        path: PATH_DASHBOARD.root + '/popup',
        icon: ICONS.kanban,
      },
    ],
  },
];

export default navConfig;
