import { createContext, ReactNode, useReducer } from 'react';
// @types
import { ActionMap } from '../@types/auth';
import { Language, Level, UtilityContextState, UtilityContextType } from 'src/@types/package';
import PackageService from 'src/services/PackageService';
import ArticleService from 'src/services/ArticleService';

// ----------------------------------------------------------------------

enum Types {
  LanguageRegister = 'LANGUAGE_REGISTER',
  LevelRegister = 'LEVEL_REGISTER',
}

type UtilityPayload = {
  [Types.LanguageRegister]: {
    languages: Language[];
  };
  [Types.LevelRegister]: {
    levels: Level[];
  };
};

export type UtilityActions = ActionMap<UtilityPayload>[keyof ActionMap<UtilityPayload>];

const initialState: UtilityContextState = {
  languages: null,
  levels: null,
};

const LessonReducer = (state: UtilityContextState, action: UtilityActions) => {
  switch (action.type) {
    case Types.LanguageRegister:
      return {
        ...state,
        languages: action.payload.languages,
      };
    case Types.LevelRegister:
      return {
        ...state,
        levels: action.payload.levels,
      };
    default:
      return state;
  }
};

const UtilityContext = createContext<UtilityContextType | null>(null);

// ----------------------------------------------------------------------

type UtilityProviderProps = {
  children: ReactNode;
};

function UtilityProvider({ children }: UtilityProviderProps) {
  const [state, dispatch] = useReducer(LessonReducer, initialState);

  const registerLanguage = async () => {
    const response = await PackageService.getLanguages();
    dispatch({
      type: Types.LanguageRegister,
      payload: {
        languages: response.data
      }
    });
  }

  const registerLevels = async () => {
    const response = await ArticleService.getLevels();
    dispatch({
      type: Types.LevelRegister,
      payload: {
        levels: response.data
      }
    });
  }

  return (
    <UtilityContext.Provider
      value={{
        ...state,
        registerLanguage,
        registerLevels,
      }}
    >
      {children}
    </UtilityContext.Provider>
  );
}

export { UtilityContext, UtilityProvider };
