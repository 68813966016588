import fetch from './interceptors'

const ArticleService = {
    getArticles: function (params: any) {
        return fetch({
            url: '/api/v2/article/action',
            method: 'get',
            headers: {
                'public-request': 'true'
            },
            params: params
        })
    },
    getArticle: function (id: any) {
        return fetch({
            url: `/api/v2/article/action/${id}`,
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    },
    getLevels: function () {
        return fetch({
            url: '/api/v2/utility/level',
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    },
    createArticle: function (data: any) {
        return fetch({
            url: '/api/v2/article/action',
            method: 'post',
            headers: {
                'public-request': 'true'
            },
            data
        })
    },
    updateArticle: function ({ id, data }: { id: any, data: any }) {
        return fetch({
            url: `/api/v2/article/action/${id}`,
            method: 'post',
            headers: {
                'public-request': 'true'
            },
            data
        })
    },
    getParagraphs: function (id: any) {
        return fetch({
            url: `/api/v2/article/action/${id}/paragraph`,
            method: 'get',
            headers: {
                'public-request': 'true'
            },
        })
    },
    createParagraph: function ({ myForm, id }: { myForm: any, id: any }) {
        return fetch({
            url: `/api/v2/article/action/${id}/paragraph`,
            method: 'post',
            data: myForm
        });
    },
    editParagraph: function ({ myForm, a_id, p_id }: { myForm: any, a_id: any, p_id: any }) {
        return fetch({
            url: `/api/v2/article/action/${a_id}/paragraph/${p_id}`,
            method: 'post',
            data: myForm
        });
    },
    generateAudio: function({ a_id }: { a_id: any }) {
        return fetch({
            url: `/api/v2/article/action/${a_id}/audio`,
            method: 'post',
        });
    },
    writeTimeStamps: function ({ a_id, data }: { a_id: any, data: any }) {
        return fetch({
            url: `/api/v2/article/action/${a_id}/timestamps`,
            method: 'post',
            data
        });
    },
    deleteParagraph: function({ a_id, p_id }: { a_id: any, p_id: any }) {
        return fetch({
            url: `/api/v2/article/action/${a_id}/paragraph/${p_id}`,
            method: 'delete',
        });
    },
    deleteArticle: function({a_id}: {a_id: any}) {
        return fetch({
            url: `/api/v2/article/action/${a_id}`,
            method: 'delete',
        });
    }
}

export default ArticleService